<template>
    <button v-if="$route.name != 'Dashboard'" ref="component_btn_back" class=" btn-outline-dark btn-rounded btn" @click="backPage()"> 
        <h6>
            <i class="fa fa-arrow-circle-left" style="vertical-align: sub;"></i> 
            Regresar
        </h6>
    </button>
</template>
<script>
export default {
    methods: {
        backPage(){
            this.$router.go(-1)
        }
    },
}
</script>