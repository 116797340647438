<template>
    <div id="app">
        <router-view></router-view>
        <loading></loading>
    </div>
</template>
<script>
    import Loading from './components/partials/Loading.vue';
    import axios from "axios";
    
    export default {
        name: 'AppPage',
        data(){
            return {
            }
        },
        created(){
            this.loadMerchant();
        },
        components: { Loading },
        methods: {
            loadMerchant(){
                this.$axios
                .get(`/${this.$subdomain}/configuration`)
                .then(response => {
                    let body = {
                        address: response.data.data.address,
                        agency: response.data.data.agency,
                        decimal_amount: response.data.data.decimal_amount,
                        enabled: response.data.data.enabled,
                        identifier: response.data.data.identifier,
                        agency_owner: response.data.data.agency_owner,
                        image: this.$baseUrlBest+response.data.data.image,
                        name: response.data.data. name,
                        usd : response.data.setting.usd,
                    };
                    
                    localStorage.setItem("configuration", JSON.stringify(body));
                })
                .catch((e) => {
                    this.$store.commit("setErrors", e);
                });
            },
        }
    }
</script>