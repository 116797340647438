<template>
    <div v-if="items">
        <h6 v-for="(item, index) in items" :key="'bread'+index">
            {{ item }}
        </h6>
    </div>
</template>
<script>
export default {
    name        : 'BreadcrumbSubPage',
    props       : [ 'items' ],
    data(){
        return {
        }
    },
}
</script>