import store from '../../store';

export function ACCESS_MENU(idMenu) {

    if(store.state.auth.menuData == null){
        return {
            menu: {
                icon : "",
                title: ""
            }
        };
    }
    
    let data = store.state.auth.menuData.find((menu) => {
        return menu.menu_id == idMenu;
    });

    if(data){
        return data;
    }

    return false;
}

export function ACCESS_MENU_ACTION(routeName) {
    
    if(store.state.auth.actionData == null){
        return false;
    }

    let data = store.state.auth.actionData.find((route) => {
        return route.menu_action.route == routeName;
    });

    if(data){
        return data;
    }

    return false;
}