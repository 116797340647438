export const HOME           = 1;
export const BRANCH_OFFICE  = 2;
export const USERS          = 3;
export const OPERATORS      = 4;
export const CONVEYORS      = 5;
export const UNITS          = 6;
export const SERVICES       = 7;
export const RESERVATIONS   = 8;
export const COUPON         = 9;
export const BOX            = 10;
export const SAVING         = 11;
export const PROMOTIONS     = 12;
export const BANKS          = 13;
export const PROFILES       = 14;
export const ACTIVITY       = 15;
export const REPORTS        = 16;
export const PAYMENT_MULTIPLE = 17;
export const SERVICE_QUOTE = 18;