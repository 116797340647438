<template>
    <div v-if="isLoading" class="body-loader">
        <span class="loader"></span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "LoadingPage",
    data() {
        return {
            config: "",
            letters: "",
        }
    },
    mounted() {
        setTimeout(() => {
            const config = JSON.parse(localStorage.getItem('configuration'));
            if (config !== undefined && config !== null) {
                this.config = config;
                this.letters = config.name.split('');
            }
        }, 500);
    },
    computed: {
        ...mapState(['isLoading'])
    },
}
</script>

<style lang="css">
.body-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
}

.loader {
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    position: absolute;
}

.loader:before,
.loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
}

</style>