<template>
       <!-- Header -->
		<div class="header">
			<div class="header-left"> 
				<router-link to="/admin/dashboard" class="logo logo-small">
					<img :src="config.image" alt="Logo" width="30" height="30">
                </router-link>
			</div>
			<a href="javascript:void(0);" id="toggle_btn" @click="enabledMenu">
				<i class="fas fa-align-left"></i>
			</a>
			<a class="mobile_btn" id="mobile_btn" href="javascript:void(0);">
				<i class="fas fa-align-left"></i>
			</a>
			<ul class="nav user-menu">
				<li  class="d-flex align-items-center">
					<span id="currencyExchange">USD ${{config.usd}}</span>
				</li>
				<!-- Notifications -->
				<li class="nav-item dropdown noti-dropdown">
					<a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
						<i class="far fa-bell"></i>  <span class="badge badge-pill" v-if="totalNotification > 0"></span>
					</a>
					<div class="dropdown-menu dropdown-menu-right notifications">
						<div class="topnav-dropdown-header">
							<span class="notification-title">Notificaciones ( {{totalNotification}} )</span>
							<a v-if="totalNotification > 0" class="clear-noti" @click="clearAllNotifications()"> Limpiar todo </a>
						</div>
						<div class="noti-content">
							<ul class="notification-list">
								<li class="notification-message" v-for="item in notifications" :key="'noti'+item.id" @click="getNotifications()"> 
									<router-link :to="`${item.data.url}?notification=${item.id}`">
										<div class="media">
											<span class="avatar avatar-sm">
												<img class="avatar-img rounded-circle" alt="" :src="item.data.icon">
											</span>
											<div class="media-body">
												<p class="noti-details">
													<span class="noti-title"> {{ item.data.title }} </span>
												</p>
												<p class="noti-time">
													<span class="notification-time"> {{ item.data.date | date_format_time }} </span>
												</p>
											</div>
										</div>
									</router-link>
								</li>
							</ul>
						</div>
						<div class="topnav-dropdown-footer">
							<a class="clear-noti" @click="getNotifications()" >Actualizar</a>
						</div>
					</div>
				</li>
				<!-- /Notifications -->
				
				<!-- User Menu -->
				<li class="nav-item dropdown">
					<a href="javascript:void(0)" class="dropdown-toggle user-link  nav-link" data-toggle="dropdown">
						<span class="user-img">
							<img class="rounded-circle" src="@/assets/img/user.jpg" width="40" alt="Admin">
						</span>
					</a>
					<div class="dropdown-menu dropdown-menu-right">
						<router-link class="dropdown-item" to="/admin/perfil">Perfil</router-link>
						<router-link v-if="ACCESS_MENU_ACTION(AGENCY_CONFIGURATIONS_INDEX)" class="dropdown-item" to="/admin/configuracion">Configuración</router-link>
						<a class="dropdown-item" href="javascript:;" @click="logout()">Cerrar sesión</a>
					</div>
				</li>
				<!-- /User Menu -->
				
			</ul>
		</div>
		<!-- /Header -->
</template>
<script>
	import { mapActions } from "vuex";
	import { ROUTE_LOGIN } from "../../enums/RouteNameEnums";
	import { ACCESS_MENU_ACTION } from '../../validations/auth/AccessControlList'
	import { AGENCY_CONFIGURATIONS_INDEX } from '../../enums/MenuActionEnums';
	export default {
		name: 'TopBar',
		data(){
			return{
				config 				: {},
				notifications		: [],
				totalNotification	: 0,
				AGENCY_CONFIGURATIONS_INDEX,
			}
		},
		created(){
			this.getNotifications();

			window.Push.Permission.request();
            window.Echo.private(`App.Models.User.${localStorage.getItem("userNumber")}`)
            .notification((notification) => {
                if(notification.icon){
                    window.Push.create(notification.title, {
                        body: notification.body,
                        link: notification.url,
                        icon: notification.icon,
                        onClick: function () {
                            window.location.href = notification.url;
                            this.close();
                        }
                    });
                }
                this.getNotifications();
            });
		},
		mounted() {

			setTimeout(() => {
				const config = JSON.parse(localStorage.getItem('configuration'));
				if (config !== undefined && config !== null) {
					this.config = config;
				}
			}, 2000);

			
		},
		methods:{
			ACCESS_MENU_ACTION,
			...mapActions("auth", ["sendLogoutRequest"]),
			logout() {
				this.sendLogoutRequest();
				setTimeout(()=>{
					this.$router.push({ name: ROUTE_LOGIN });
				},1500);
				
			},
			getNotifications(){
                fetch(`${this.$baseUrl}/api/${this.$subdomain}/notifications`,{
					headers: {
						'Authorization' : `Bearer ${localStorage.getItem("authToken")}`
					},
				})
                .then(response => response.json())
				.then(data => {
					this.notifications 		= data.data;
					this.totalNotification	= data.count;
				})
				.catch(error => {
					console.error('Error en la fetch:', error);
				});
            },
			enabledMenu()
			{
				if(document.body.classList.contains("mini-sidebar"))
				{
					document.body.classList.remove('mini-sidebar');
				}
				else
				{
					document.body.classList.add('mini-sidebar');
				}
				
			},
			clearAllNotifications(){
				this.$axios
				.post(`/${this.$subdomain}/notifications-clear-all`,{
					'_method'           : 'PUT'
				})
				.then(response => {
					this.getNotifications();
				})
				.catch((e) => {
					this.$store.commit("setErrors", e);
				});
			},
		} 
	}
</script>
<style>
ul li #currencyExchange{
	color: white;
	margin-right: 10px;
}

.clear-noti{
	cursor: pointer;
}
</style>